import React from 'react'
import { NavLink } from 'react-router-dom'

const BoardofDirectors = () => {
  return (
    <>
          <section className="breadcrum">
        <div className="container-fluid">
            <div className="row">
                <div
                    className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                    <h1 className="text-white">Board of Directors
                    </h1>
                </div>
            </div>
        </div>
    </section>
    <section className=" mt-5 animation-element slide-up testimonial in-view">
      <div className="container pt-4">
          <h2 className="text-center">EXECUTIVE DIRECTOR</h2>
          <div className="row pt-4">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2 animation-element slide-up testimonial in-view">
              <div className="borad-member boradofdire">
                <div className="borad-member-img"> 
                <img src="../assets/img/Kamlesh-Sharma-MD.png" className="w-100 height-500-bod margin-auto width-75 d-flex"
                    alt="Kamlesh-Sharma"/>
                </div>
                <div className="borad-member-content">
                  <h4>Shri Kamlesh Sharma</h4>
                  <p>Chairman & Managing Director<br/>
                  </p>
                  <div className="readmore">
                    <div className="resourceButton">
                      <NavLink to="/KamleshSharma" className="text-decoration-none">Read more
                        <img src="../assets/img/shareIconWhite.png" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2 animation-element slide-up testimonial in-view">
                <div className="borad-member boradofdire">
                  <div className="borad-member-img"> 
                  <img src="../assets/img/Suresh-sharma.png" className="w-100 height-500-bod margin-auto width-75 d-flex"
                      alt="Kamlesh-Sharma" />
            
                  </div>
                  <div className="borad-member-content">
                    <h4>Shri Suresh Sharma</h4>
                    <p>Whole Time Director<br/>
                    </p>
                    <div className="readmore">
                      <div className="resourceButton">
                        <NavLink to="/SureshSharma" className="text-decoration-none">Read more
                          <img src="../assets/img/shareIconWhite.png" /></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2 animation-element slide-up testimonial in-view">
                <div className="borad-member boradofdire">
                  <div className="borad-member-img"> 
                  <img src="../assets/img/vikas-sharma.png" className="w-100 height-500-bod margin-auto width-75 d-flex"
                      alt="vikas-sharma"/>
            
                  </div>
                  <div className="borad-member-content">
                    <h4>Shri Vikas Sharma</h4>
                    <p>Executive Director<br/>
                    </p>
                    <div className="readmore">
                      <div className="resourceButton">
                        <NavLink to="/VikassharmaNew" className="text-decoration-none">Read more
                          <img src="../assets/img/shareIconWhite.png"/></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </section>

<section className="mt-5 animation-element slide-up testimonial in-view">
  <div className="container">
      <h2 className="text-center">NON- EXECUTIVE DIRECTOR</h2>
      <div className="row pt-4 justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2 animation-element slide-up testimonial in-view">
            <div className="borad-member boradofdire">
              <div className="borad-member-img"> 
              <img src="../assets/img/sheela-sharma.png" className="w-100 height-500-bod  margin-auto width-75 d-flex"
                  alt="SheelaSharma" />
        
              </div>
              <div className="borad-member-content">
                <h4>Smt. Sheela Suresh Sharma</h4>
                <p>Non-Executive Director<br/>
                </p>
                <div className="readmore">
                  <div className="resourceButton">
                    <NavLink to="/SheelaSharma" className="text-decoration-none  margin-auto width-75 d-flex">Read more
                      <img src="../assets/img/shareIconWhite.png" /></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
     
      </div>
  </div>
</section>
<section className="mt-5 animation-element slide-up testimonial in-view">
  <div className="container">
      <h2 className="text-center">INDEPENDENT DIRECTOR</h2>
      <div className="row pt-4 justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2 animation-element slide-up testimonial in-view">
            <div className="borad-member boradofdire">
              <div className="borad-member-img"> 
              <img src="../assets/img/yogesh-kumar-gupta.png" className="w-100 height-500-bod  margin-auto width-75 d-flex"
                  alt="vikas-sharma" />
              </div>
              <div className="borad-member-content">
                <h4>Shri Yogesh Kumar Gupta</h4>
                <p>Independent Director<br/>
                </p>
                <div className="readmore">
                  <div className="resourceButton">
                    <NavLink to="/Yogeshgupta" className="text-decoration-none">Read more
                      <img src="../assets/img/shareIconWhite.png" alt="" />
                      </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2 animation-element slide-up testimonial in-view">
            <div className="borad-member boradofdire">
              <div className="borad-member-img"> 
              <img src="../assets/img/govind-maherwal.png" className="w-100 height-500-bod  margin-auto width-75 d-flex"
                  alt="vikas-sharma" 
                  />
        
              </div>
              <div className="borad-member-content">
                <h4>Shri Govind Maherwal</h4>
                <p>Independent Director<br/>
                </p>
                <div className="readmore">
                  <div className="resourceButton">
                    <NavLink to="/GovindMaherwal" className="text-decoration-none">Read more
                    <img src="../assets/img/shareIconWhite.png  margin-auto width-75 d-flex" alt="" />
                      </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</section>
    </>
  )
}

export default BoardofDirectors
