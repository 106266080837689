import React from 'react'

const BoardCommittees = () => {
  return (
    <>
          <section class="breadcrum">
        <div class="container-fluid">
            <div class="row">
                <div href="#"
                    class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                    <h1 class="text-white">Board Committees
                    </h1>
                </div>
            </div>
        </div>
    </section>
    <section class="mt-5 animation-element slide-up testimonial in-view">
        <div class="container">
            <div class="row">
                <h2 class="green-color mb-5">AUDIT COMMITTEE</h2>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind"
                        src="../assets/img/govind-maherwal.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Govind Govind
                        </h5>
                        <h6 class="text-center mt-3">Chairman
                        </h6>
                        <p class="text-center"><b>Independent Director</b></p>
                       
                      </div>
                    </div>
                  </div>
                </div> 
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind"
                        src="../assets/img/yogesh-kumar-gupta.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Yogesh Kumar Gupta
                        </h5>
                        <h6 class="text-center mt-3">Member
                        </h6>
                        <p class="text-center"><b>Independent Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind"
                        src="../assets/img/Suresh-sharma.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Suresh Sharma
                        </h5>
                        <h6 class="text-center mt-3">Member
                        </h6>
                        <p class="text-center"><b>Whole Time Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="row mt-5">
                <h2 class="green-color mb-5">NOMINATION AND REMUNERATION COMMITTEE</h2>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind"
                        src="../assets/img/sheela-sharma.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Sheela Suresh Sharma
                        </h5>
                        <h6 class="text-center mt-3">Chairperson
                        </h6>
                        <p class="text-center"><b>Non-Executive Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind"
                        src="../assets/img/govind-maherwal.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Govind Maherwal
                        </h5>
                        <h6 class="text-center mt-3">Member
                        </h6>
                        <p class="text-center"><b>Independent Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="yogesh"
                        src="../assets/img/yogesh-kumar-gupta.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Yogesh Kumar Gupta
                        </h5>
                        <h6 class="text-center mt-3">Member
                        </h6>
                        <p class="text-center"><b>Independent Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="row mt-5">
                <h2 class="green-color mb-5">STAKEHOLDER’S RELATIONSHIP COMMITTEE</h2>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind" src="../assets/img/sheela-sharma.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Sheela Suresh Sharma
                        </h5>
                        <h6 class="text-center mt-3">Chairperson 
                        </h6>
                        <p class="text-center"><b>Non-Executive Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind"
                        src="../assets/img/govind-maherwal.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Govind Maherwal
                        </h5>
                        <h6 class="text-center mt-3">Member
                        </h6>
                        <p class="text-center"><b>Independent Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                  <div class="boardMemeberBox cursor-pointer">
                    <div class="box">
                      <img alt="Govind Govind"
                        src="../assets/img/yogesh-kumar-gupta.png" class="w-100 height-500-bod" />
                      <div class="boxContent ">
                        <h5 class="text-center">Yogesh Kumar Gupta
                        </h5>
                        <h6 class="text-center mt-3">Member
                        </h6>
                        <p class="text-center"><b>Independent Director</b></p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BoardCommittees
